<template>
  <div class="remove-account">

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="container">
      <h2>How do I delete my account?</h2>
      <p>While we are sad to see you go, your privacy, safety and security is always our priority</p>

      <p>If you’re an individual user looking to delete your account, you’ll
         need send an email to <span class='fw-bold'>support@mbattendance.com</span> with your request. 
         We’ll submit your information to be cleared from the system which 
         takes about 72 hours to complete.
        </p>

        <p>When we submit your account for deletion all your login and personal details are permanently deleted.</p>


    </div>

  </div>
</template>