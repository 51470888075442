<template>
  <div class="container">
    <h1>Privacy Policy</h1>
    <div>
      
      <p>
        Please read our privacy policy carefully to get a clear understanding of how 
        we collect, use, protect or otherwise handle your Personally Identifiable 
        Information in accordance with our website.
      </p>
      <p class="fw-bold">
        What personal information do we collect from the people that visit our website 
        or app?
      </p>

      <p>
        To use the MB Attendance System, you may be asked to enter your name and email 
        address. When logging your attendance, the system automatically retrieves your 
        location.
      </p>

      <p class="fw-bold">When do we collect information?</p>
      <p>
        We collect information from you upon registration to the MB Attendance 
        System and when you log your attendance.
      </p>
      <p class="fw-bold">
        How do we use your information?
      </p>
      <p>
        We may use the information we collect from you in the following ways:
        <ul>
          <li>To generate automated Daily Time Record.</li>
          <li>To allow us to better service you in responding to your customer service requests.</li>
          <li>To quickly process your transactions.</li>
        </ul>
      </p>

      <p class="fw-bold">
        How do we protect your information?
      </p>
      <p>
        Information is contained behind secured networks and is only accessible by a 
        limited number of persons who have special access rights to such systems, 
        and are required to keep the information confidential. In addition, all 
        sensitive/credit information you supply is encrypted via Secure Socket 
        Layer (SSL) technology.
      </p>

      <p class="fw-bold">
         Do we use 'cookies'? How do we use them?
      </p>
      <p>No. We do not use cookies.</p>

      <p class="fw-bold">Third-party disclosure</p>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your Personally
 Identifiable Information unless we provide users with advance notice. 
 This does not include website hosting partners and other parties who assist
  us in operating our system, conducting our business, or serving our users,
   so long as those parties agree to keep this information confidential. 
   We may also release information when it's release is appropriate to comply 
   with the law, enforce our site policies, or protect ours or others' rights, 
   property or safety. However, non-personally identifiable visitor information
    may be provided to other parties for marketing, advertising, or other uses.
      </p>
      <p class="fw-bold">Third-party links</p>
      <p>We do not include or offer third-party products or services on our system.</p>
    </div>
   
  </div>
</template>

<style>

li{
   text-align: justify;
}
p {
 text-align: justify;
}
</style>
